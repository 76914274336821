import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
/* import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// core components */
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
//import Success from "components/Typography/Success.js";
//import Danger from "components/Typography/Danger.js";

import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.js";

import city from "assets/img/images/MultiBankTower.jpg";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();
  return (
    <div className="cd-section">    
      <div
        className={classes.pricing + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >     
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title + " " + classes.textCenter}>
                Affiliation ProMyTrade
              </h2>
              <h4 className={classes.title + " " + classes.textCenter}>
                Ouvrez votre Compte GOLD ou ECN d’affiliation
              </h4>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem
              xs={12}
              sm={7}
              md={7}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: "Vous êtes Investisseur",
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={10} className={classes.mlAuto + " " + classes.mrAuto}>
                          <Card pricing raised>
                            <CardBody pricing>
                              <h6 className={classes.cardDescription}>
                                COMPTE GOLD
                              </h6>
                              <h2 className={classes.cardTitle}>
                                Dépôt à partir de 2000 <small>$</small> 
                              </h2>
                              <ul>
                                <li>
                                  <b>Robot de trading gratuit </b> GOLD
                                </li>
                                <li>
                                  <b>VPS</b> Offert
                                </li>
                                <li>
                                  <b>Ouverture de compte</b> facile
                                </li>
                                <li>
                                  <b>Téléchargez</b> vos documents
                                </li>
                              </ul>
                            </CardBody>
                            <CardFooter
                              pricing
                              className={classes.justifyContentCenter}
                            >
                              <Button
                                href="https://mexatlantic.com/account/live-account?ibNum=6626625" target="_blank"
                                color="warning"
                                round
                              >
                                Compte GOLD
                              </Button>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Vous êtes Trader Actif",
                    tabContent: (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={10} className={classes.mlAuto + " " + classes.mrAuto} >
                          <Card pricing raised>
                            <CardBody pricing>
                              <h6 className={classes.cardDescription}>
                                COMPTE ECN
                              </h6>
                              <h2 className={classes.cardTitle}>
                                Dépôt à partir 250 <small>$</small>
                              </h2>
                              <ul>
                              <li>
                                  <b>Speads à partir de 0.0 pips </b> ECN
                                </li>
                                <li>
                                <b>Avantages</b> Considérables
                                </li>
                                <li>
                                  <b>Ouverture de compte</b> facile
                                </li>
                                <li>
                                  <b>Téléchargez</b> vos documents
                                </li>
                              </ul>
                            </CardBody>
                            <CardFooter
                              pricing
                              className={classes.justifyContentCenter}
                            >
                              <Button
                                href="https://mexatlantic.com/iframe/2716" target="_blank"
                                color="primary"
                                round
                              >
                                Compte ECN
                              </Button>
                             
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Pricing 5 END */}
    </div>
  );
}
