/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
//import HeaderLinks from "components/Header/HeaderLinks.js";
//import Footer from "components/Footer/Footer.js";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import ToggleVisibilityNavigation from "components/ToggleVisibility/ToggleVisibilityNavigation.js";
import SectionExamples from "views/PresentationPage/Sections/SectionExamples.js";
import SectionComponents from "views/PresentationPage/Sections/SectionComponents.js";
import SectionContent from "views/PresentationPage/Sections/SectionContent.js";

import SectionPricing from "views/PresentationPage/Sections/SectionPricing.js";

import SectionSocialMedia from "views/PresentationPage/Sections/SectionSocialMedia.js";


import SectionFooter from "views/PresentationPage/Sections/SectionFooter.js";


import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

import videoBackground from "assets/videos/ProMyTrade.mp4";

import "./style.css";


const useStyles = makeStyles(presentationStyle);


export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="ProMyTrade"
        /* links={<HeaderLinks dropdownHoverColor="info" />} */
        fixed
        color="transparent"
       /*  changeColorOnScroll={{
          height: 700,
          color: "white"
        }} */
      />
      <div className="main">
        <div className="overlay"></div>
        <video className="videoCover" src={videoBackground} autoPlay muted loop playsInline />
        <div className="content">
          <span className={classes.shadowTitle}>
            <span className={classes.proBadge}>ProMyTrade</span>
          </span>
          <br></br>
          <h3 className={classes.title}>
            Trader avec MultiBank Group
          </h3>
          <br></br>
          <ToggleVisibilityNavigation />
        </div>
      </div>
      <div className={classes.main}>
        <SectionExamples />
        <SectionComponents />       
       </div>
      <SectionPricing />
      <SectionContent />
      <SectionSocialMedia />

      <SectionFooter />
      {/* <Link to="/components" className={classes.dropdownLink}>
              All components
            </Link> */}
    </div>
  );
}
