import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import ArrowForwardIcon from '@material-ui/icons/ArrowDownwardOutlined';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";


const ArrowForwardIconAnimated = styled(ArrowForwardIcon)``;
const useStyles = makeStyles(presentationStyle);

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};
const smoothScroll = (e, target) => {
  var isMobile = navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  );
  if (isMobile) {
     scrollGo(document.documentElement, 550, 1250);
  } else {
    e.preventDefault();
    scrollGo(document.documentElement, 750, 1250);
  }
};

const scrollGo = (element, to, duration) => {
var start = element.scrollTop,
  change = to - start,
  currentTime = 0,
  increment = 20;

var animateScroll = function () {
  currentTime += increment;
  var val = easeInOutQuad(currentTime, start, change, duration);
  element.scrollTop = val;
  if (currentTime < duration) {
    setTimeout(animateScroll, increment);
  }
};
animateScroll();
};

export default function ToggleVisibilityNavigation() {
  const classes = useStyles();
    return (
      <Link to={""}>
        <Button
          color="info"
          endIcon={<ArrowForwardIconAnimated />}
         className={classNames(classes.navButton, classes.shadowForButton)}
          round
          onClick={e => smoothScroll(e, "SearchPart")}
        >
      Obtenez votre compte
        </Button>
      </Link>
    );
}

